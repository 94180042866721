import React, { useState } from "react";
import { motion } from "framer-motion";

function Hero() {
  const [show, setShow] = useState(false);

  return (
    <>
      <div className="lg:px-10 ml-10 xl:px-0">
        <div className="container mx-auto relative z-20">
          <nav className="w-full absolute">
            <div className="hidden lg:flex w-full f-f-p justify-between items-center py-6 relative">
              <div className="w-2/3 ml-6 flex">
                <img
                  className="w-14 h-14"
                  src="https://aura-yog-centre.vercel.app/static/media/logo.5b184c478a6cdb86763e.png"
                  alt=""
                />
                <p className="text-xl font-bold mt-2 ml-6">AURA YOG CENTRE</p>
              </div>
              <div className="md:w-1/2 xl:w-1/3">
                <ul className="flex justify-between w-full items-center text-gray-600">
                  <li className="border-b-4 border-black pb-1">
                    <a href>Home</a>
                  </li>
                  <li className="border-b-4 border-transparent pb-1">
                    <a href>Services</a>
                  </li>
                  <li className="border-b-4 border-transparent pb-1">
                    <a href>Why Us?</a>
                  </li>
                  <li className="border-b-4 border-transparent pb-1">
                    <a href>Testimonials</a>
                  </li>
                  <li className="border-b-4 border-transparent pb-1">
                    <a href>Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <nav className="lg:hidden">
            <div className="flex justify-between items-center -ml-6">
              <div>
                <img
                  className="w-16 h-16"
                  src="https://aura-yog-centre.vercel.app/static/media/logo.5b184c478a6cdb86763e.png"
                  alt=""
                />
                <p className="text-xl font-bold mt-2 ml-4">AURA YOG CENTRE</p>
              </div>
              <div className=" flex items-center">
                {show && (
                  <ul
                    id="list"
                    className=" p-2 border-r bg-white absolute rounded top-0 left-0 right-0 shadow mt-16 md:mt-16"
                  >
                    <li className="flex cursor-pointer text-gray-600 text-sm leading-3 tracking-normal mt-2 py-2 hover:text-indigo-700 focus:text-indigo-700 focus:outline-none">
                      <a href="javascript:void(0)">
                        <span className="ml-2 font-bold">Home</span>
                      </a>
                    </li>
                    <li
                      className="flex flex-col cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-2 hover:text-indigo-700 focus:text-indigo-700 focus:outline-none  justify-center"
                      onclick="dropdownHandler(this)"
                    >
                      <a href="javascript:void(0)">
                        <span className="ml-2 font-bold">Services</span>
                      </a>
                    </li>
                    <li className="flex cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-2 hover:text-indigo-700  items-center focus:text-indigo-700 focus:outline-none">
                      <a href="javascript:void(0)">
                        <span className="ml-2 font-bold">Why Us?</span>
                      </a>
                    </li>
                    <li
                      className="flex flex-col cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-2 hover:text-indigo-700 focus:text-indigo-700 focus:outline-none  justify-center"
                      onclick="dropdownHandler(this)"
                    >
                      <a href="javascript:void(0)">
                        <span className="ml-2 font-bold">Testimonials</span>
                      </a>
                    </li>
                    <li
                      className="flex flex-col cursor-pointer text-gray-600 text-sm leading-3 tracking-normal pt-2 pb-4 hover:text-indigo-700 focus:text-indigo-700 focus:outline-none  justify-center"
                      onclick="dropdownHandler(this)"
                    >
                      <a href="javascript:void(0)">
                        <span className="ml-2 font-bold">About Us</span>
                      </a>
                    </li>
                  </ul>
                )}
                <div className="xl:hidden" onClick={() => setShow(!show)}>
                  {show ? (
                    <div id="close" className=" close-m-menu">
                      <svg
                        aria-label="Close"
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <line x1={18} y1={6} x2={6} y2={18} />
                        <line x1={6} y1={6} x2={18} y2={18} />
                      </svg>
                    </div>
                  ) : (
                    <svg
                      id="open"
                      aria-haspopup="true"
                      aria-label="Main Menu"
                      xmlns="http://www.w3.org/2000/svg"
                      className="show-m-menu icon icon-tabler icon-tabler-menu"
                      width={28}
                      height={28}
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <line x1={4} y1={8} x2={20} y2={8} />
                      <line x1={4} y1={16} x2={20} y2={16} />
                    </svg>
                  )}
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div className="mx-auto container relative z-0 px-4 xl:px-0">
          <div className="flex flex-col-reverse md:flex-row">
            <motion.div
              initial={{ opacity: 0, x: -70 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{
                duration: 0.6,
                delay: 0.5,
                ease: "easeInOut",
              }}
              className="md:w-3/5 md:pt-24 pb-10 lg:py-32 xl:py-48 sm:ml-10"
            >
              <h1 className="text-2xl lg:text-4xl xl:text-4xl font-black text-gray-900 text-center md:text-left tracking-tighter f-f-i md:w-7/12 text-heading-color">
                We, Discover Serenity Within : Your Journey to Balance,
                Strength, and Inner Peace.{" "}
              </h1>
              <h2 className="md:w-8/12 py-4 text-center md:text-left md:py-8 text-gray-900 text-lg lg:text-lg">
                Aura yog centre, is a well being centre with an aim of spreading
                the Teaching and practice of an authentic and ancient system of
                yoga thereby promoting health, well being, freedom from stress
                and more balanced and visionary individual.
              </h2>
            </motion.div>
            <div className="w-1/2 sm:w-2/5 h-64 md:h-auto m-auto flex items-center overflow-hidden">
              {/* <img class="h-full" src="https://cdn.tuk.dev/assets/components/111220/Hero4/Rectangle.png" alt="Device"> */}
              <img
                style={{ borderRadius: "600px", marginLeft: "-160px" }}
                className=" mr-20 md:absolute md:w-1/2 mt-20 big-img"
                src="heroImg.png"
                alt="img"
              />
              <img
                className="absolute w-2/3 -ml-12 rounded-2xl small-img"
                src="heroImg.png"
                alt="img"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
