import React from "react";
import Hero from "../Components/Landing/Hero";
import Culture from "../Components/Landing/Culture";
import WhyUs from "../Components/Landing/WhyUs";
import Testimonials from "../Components/Landing/Testimonials";
import Contact from "../Components/Landing/Contact";
import Footer from "../Components/Landing/Footer";
import Videos from "../Components/Landing/Videos";
import Services from "../Components/Landing/Services";

export default function LandingPage() {
  return (
    <div>
      <Hero />
      <Culture />
      <Services />
      <br />
      <WhyUs />
      <Videos />
      <Testimonials />
      <Contact />
      <Footer />
    </div>
  );
}
