import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

function Culture() {
  const { ref, inView } = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.1, // Trigger when 10% of the component is in view
  });
  return (
    <div className="culture bg-gray-50 py-20 flex flex-col items-center justify-center ">
      <div className="xl:w-1/2 w-11/12">
        <h1
          role="heading"
          tabIndex={0}
          className="lg:text-4xl text-3xl font-extrabold leading-9 text-gray-800"
        >
          Transforming hundreds of lives better
        </h1>
      </div>
      <div className="2xl:px-20 ml-20 lg:px-12 px-4 flex flex-wrap items-start mt-4">
        <div className="mt-24">
          <motion.div
            ref={ref}
            initial={{ opacity: 0, x: -70 }}
            animate={inView ? { opacity: 1, x: 0 } : {}}
            transition={{
              duration: 0.6,
              delay: 0.5,
              ease: "easeInOut",
            }}
            className="flex items-end "
          >
            <img
              tabIndex={0}
              src="img1.png"
              alt="girl with blue background"
              className="w-20 h-20 rounded-lg mr-6"
            />
            <img
              tabIndex={0}
              src="img2.png"
              alt="guy winking"
              className="w-48 h-36 rounded-lg"
            />
          </motion.div>
          <motion.div
            ref={ref}
            initial={{ opacity: 0, x: -70 }}
            animate={inView ? { opacity: 1, x: 0 } : {}}
            transition={{
              duration: 0.6,
              delay: 0.5,
              ease: "easeInOut",
            }}
            className="flex items-center justify-end my-6"
          >
            <img tabIndex={0} src="img4.png" alt="guy smiling" />
          </motion.div>
          <motion.div
            ref={ref}
            initial={{ opacity: 0, x: -70 }}
            animate={inView ? { opacity: 1, x: 0 } : {}}
            transition={{
              duration: 0.6,
              delay: 0.5,
              ease: "easeInOut",
            }}
            className="flex items-start"
          >
            <img
              tabIndex={0}
              src="img3.png"
              alt="girl with bluw background"
              className="w-48 h-48 rounded-lg"
            />
            <img
              tabIndex={0}
              src="img5.png"
              alt="guy with glasses"
              className="w-20 h-20 rounded-lg ml-6 flex-shrink-0 object-cover object-fit"
            />
          </motion.div>
        </div>
        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: -70 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{
            duration: 0.6,
            delay: 0.5,
            ease: "easeInOut",
          }}
          className="ml-6 mt-32"
        >
          <img
            tabIndex={0}
            src="img6.png"
            className="w-72 h-80 rounded-lg"
            alt="guy with sunglasses"
          />
          <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 70 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{
              duration: 0.6,
              delay: 0.5,
              ease: "easeInOut",
            }}
            className="flex items-start mt-6"
          >
            <img
              tabIndex={0}
              src="img7.png"
              alt="girl  laughing"
              className="w-48 h-48 rounded-lg"
            />
            <img
              tabIndex={0}
              src="img8.png"
              alt="guy with glasses"
              className="w-20 h-20 rounded-lg ml-6 object-cover object-fit"
            />
          </motion.div>
        </motion.div>
        <motion.div
          ref={ref}
          initial={{ opacity: 0, x: 70 }}
          animate={inView ? { opacity: 1, x: 0 } : {}}
          transition={{
            duration: 0.6,
            delay: 0.5,
            ease: "easeInOut",
          }}
          className="mt-14 ml-6"
        >
          <div className="lg:flex ">
            {/* <div>
              <img
                tabIndex={0}
                src="img9.png"
                alt="group of friends"
                className="w-96 h-72 rounded-lg object-center object-fit"
              />
            </div> */}
            <div>
              <div className="flex ml-6">
                <img
                  tabIndex={0}
                  src="img1.png"
                  className="w-20 h-20 rounded-lg mt-14"
                  alt="man"
                />
                <img
                  tabIndex={0}
                  src="img2.png"
                  className="w-20 h-24 rounded-lg ml-6"
                  alt="woman"
                />
              </div>
              <img
                tabIndex={0}
                src="img3.png"
                alt="boy with blonde hair"
                className="ml-6 mt-6 w-48 h-32 rounded-lg"
              />
            </div>
          </div>
          <div className="mt-6 flex">
            <img
              tabIndex={0}
              className="w-48 h-48 rounded-lg"
              src="img4.png"
              alt="young girl with red hair"
            />
            <img
              tabIndex={0}
              className="w-72 h-56 rounded-lg ml-6"
              src="img5.png"
              alt="young girl with red hair"
            />
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default Culture;
