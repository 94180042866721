import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function Testimonials() {
  const { ref, inView } = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.1, // Trigger when 10% of the component is in view
  });
  return (
    <div className="py-16 px-20 bg-gray-50">
      <div className="container mx-auto px-4 flex flex-col lg:items-center justify-between lg:flex-row">
        <motion.div
          ref={ref}
          initial={{ opacity: 0, x: -70 }}
          animate={inView ? { opacity: 1, x: 0 } : {}}
          transition={{
            duration: 0.6,
            delay: 0.5,
            ease: "easeInOut",
          }}
          className="mb-14 xl:mb-0"
        >
          <h1 className="lg:text-4xl text-3xl font-extrabold leading-9 text-gray-800">
            Our customers love
            <br />
            what we do
          </h1>
          <p className="mt-4 text-base leading-normal text-gray-600 md:w-2/3 lg:w-3/4 pr-16 lg:pr-0">
            Over 100 people are with us in making their lifes meaningfull.
          </p>
          <button
            style={{ background: "#F6952A" }}
            className="hidden md:block w-full sm:w-auto mt-12 focus:outline-none focus:ring-2 focus:ring-offset-2 flex items-start justify-center sm:justify-start px-8 py-4 hover:bg-gray-600 rounded text-base font-medium leading-none text-center text-black"
          >
            <a
              href="https://www.google.com/search?q=aura+yog+centre+shalimar+bagh&rlz=1C1RXQR_enIN1086IN1086&oq=aura+yog+centre+shalimar+bagh&gs_lcrp=EgZjaHJvbWUyBggAEEUYOdIBCDc4NzhqMGo3qAIAsAIA&sourceid=chrome&ie=UTF-8#lrd=0x390d03d05b647191:0x5692ad546e05293,1,,,,"
              target="_blank"
              rel="noreferrer"
            >
              Read success stories
            </a>{" "}
          </button>
        </motion.div>
        <motion.div
          ref={ref}
          initial={{ opacity: 0, x: 70 }}
          animate={inView ? { opacity: 1, x: 0 } : {}}
          transition={{
            duration: 0.6,
            delay: 0.5,
            ease: "easeInOut",
          }}
          role="list"
          aria-label="Testimonials"
          className="xl:w-1/2 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-6 flex-wrap justify-center items-start"
        >
          <div role="listitem" className="bg-white shadow rounded p-4 xl:p-8">
            <img
              src="https://cdn.tuk.dev/assets/components/26May-update/quote.png"
              aria-hidden="true"
            />
            <div className="pl-4 pt-4 flex items-start justify-between">
              <div className="mr-6">
                <p className="xl:text-md xl:leading-loose text-gray-600">
                  Excellent experience. Excellent aura. Correctly named.
                  Positive atmosphere. Would recommend Everyone. Also it's very
                  important to give atleast one hour to yourself and here you
                  will fall in self love.
                </p>
                <p className="mt-4 text-base font-semibold leading-none text-gray-800">
                  Dr Jyoti Jha
                </p>
              </div>
              <img
                src="https://lh3.googleusercontent.com/a/ACg8ocLTAgM-oa5l1ShkVyfgNoVATD7mxIQyekU1Svy-l-8KxnjEkQ=s64-c-rp-mo-br100"
                alt="Display Avatar of Anna Smith"
                role="img"
              />
            </div>
          </div>
          <div role="listitem" className="bg-white shadow rounded p-4 xl:p-8">
            <img
              src="https://cdn.tuk.dev/assets/components/26May-update/quote.png"
              aria-hidden="true"
            />
            <div className="pl-4 pt-4 flex items-start justify-between">
              <div className="mr-6">
                <p className="xl:text-md xl:leading-loose text-gray-600">
                  People searching for path to self discipline -Good health and
                  inner peace your journey starts here....
                </p>
                <p className="mt-4 text-base font-semibold leading-none text-gray-800">
                  Sai Nishant
                </p>
              </div>
              <img
                src="https://lh3.googleusercontent.com/a/ACg8ocKF7kvIgZvboL9MG9_9g35VEjwDRIXhaMrQ9EPJDFvmw-W41w=s64-c-rp-mo-ba2-br100"
                alt="Display avatar of Dany John"
                role="img"
              />
            </div>
          </div>
          <div role="listitem" className="bg-white shadow rounded p-4 xl:p-8">
            <img
              src="https://cdn.tuk.dev/assets/components/26May-update/quote.png"
              aria-hidden="true"
            />
            <div className="pl-4 pt-4 flex items-start justify-between">
              <div className="mr-6">
                <p className="xl:text-md xl:leading-loose text-gray-600">
                  I had join yog one month back my body was totally stiff not
                  able to even bend properly I really feel the change in my
                  body. I am very much confident now. I really thanx to Ms.
                  Karuna for this who is our instructor, she is very polite,
                  cooperative n understanding. Really proud on her.
                </p>
                <p className="mt-4 text-base font-semibold leading-none text-gray-800">
                  jasminder bhatia
                </p>
              </div>
              <img
                src="https://lh3.googleusercontent.com/a/ACg8ocJNEc3n2RfVdPgro0Qf3rAgqvcpynggQju7bM9D8eYQ2lX3lQ=s64-c-rp-mo-br100"
                alt="Display Avatar of Mike Blake"
                role="img"
              />
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
