import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const WhyUs = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.1, // Trigger when 10% of the component is in view
  });

  return (
    <div className="overflow-y-hidden">
      <div className="xl:mx-auto xl:container  xl:px-20 md:px-6 px-4 py-12">
        <motion.div
          ref={ref}
          initial={{ opacity: 0, x: -70 }}
          animate={inView ? { opacity: 1, x: 0 } : {}}
          transition={{
            duration: 0.6,
            delay: 0.5,
            ease: "easeInOut",
          }}
          className="lg:flex items-center justify-center lg:space-x-12 2xl:space-x-6"
        >
          <div className>
            <p className="lg:text-4xl text-3xl font-extrabold leading-9 text-gray-800">
              Why Aura Yog Centre ?
            </p>
            <div className="lg:hidden lg:w-3/5 xl:w-3/5 w-full lg:mt-0 mt-6">
              <img
                src="why.jpg"
                alt="ongoing meeting"
                className="w-full obejct-fit object-center object-fill h-full"
              />
            </div>
            <div className="mt-6 md:mt-10 grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-6 lg:mt-6 2xl:mt-12">
              <div className="flex items-center">
                <div className="w-16 h-16 relative">
                  <div className=" flex-shrink-0 z-20  w-16 h-16 flex items-center justify-center mt-2 mr-3">
                    <p className="text-5xl">✨</p>
                  </div>
                </div>
                <div className="flex items-start flex-col ml-6 pt-8">
                  <h2 className="text-lg font-semibold leading-4 text-gray-800">
                    Experienced Trainers
                  </h2>
                  <p className="lg:w-40 2xl:w-52 text-base leading-6 mt-2 text-gray-600">
                    Certified and knowledgeable professionals{" "}
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <div className="w-16 h-16 relative">
                  <div className=" flex-shrink-0 z-20  w-16 h-16 flex items-center justify-center mt-2 mr-3">
                    <p className="text-5xl">✨</p>
                  </div>
                </div>
                <div className="flex items-start flex-col ml-6 pt-8">
                  <h2 className="text-lg font-semibold leading-4 text-gray-800">
                    Friendly Ambience
                  </h2>
                  <p className="lg:w-40 2xl:w-52 text-base leading-6 mt-2 text-gray-600">
                    Positive and motivating atmosphere
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <div className="w-16 h-16 relative">
                  <div className=" flex-shrink-0 z-20  w-16 h-16 flex items-center justify-center mt-2 mr-3">
                    <p className="text-5xl">✨</p>
                  </div>
                </div>
                <div className="flex items-start flex-col ml-6 pt-8">
                  <h2 className="text-lg font-semibold leading-4 text-gray-800">
                    Best Services
                  </h2>
                  <p className="lg:w-40 2xl:w-52 text-base leading-6 mt-2 text-gray-600">
                    Comprehensive fitness programs
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <div className="w-16 h-16 relative">
                  <div className=" flex-shrink-0 z-20  w-16 h-16 flex items-center justify-center mt-2 mr-3">
                    <p className="text-5xl">✨</p>
                  </div>
                </div>
                <div className="flex items-start flex-col ml-6 pt-8">
                  <h2 className="text-lg font-semibold leading-4 text-gray-800">
                    Variety of Exercies
                  </h2>
                  <p className="lg:w-40 2xl:w-52 text-base leading-6 mt-2 text-gray-600">
                    Strength training and cardio workouts
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden lg:block lg:w-3/5 xl:w-3/5 w-full lg:mt-0 mt-6">
            <img
              src="why.jpg"
              alt="ongoing meeting"
              className="w-full obejct-fit object-center object-fill h-full rounded-xl"
            />
          </div>
        </motion.div>
      </div>
    </div>
  );
};
export default WhyUs;
